<template>
    <div class="header-search w-full">
        <div class="header-form form form--header">
                <!-- Search Input -->
                <input
                    ref="searchInput"
                    v-model="query"
                    placeholder="Search for..."
                    aria-label="Search for..."
                    class="form-field flex-grow w-full !rounded-l-md"
                    @keyup.enter="performSearch"
                    @focus="handleFocus"
                    @blur="hideAutocomplete"
                />

                <!-- Autocomplete Results -->
                <div v-if="showAutocomplete && autocompleteResults.length"
                     class="absolute left-0 top-full w-full bg-white z-50 shadow-lg border border-gray-2 rounded-md mt-2 max-h-[32vh] overflow-y-auto">
                    <ul>
                        <li v-for="(result, index) in autocompleteResults"
                            :key="result.objectID"
                            class="px-4 py-3 text-black cursor-default transition duration-200 flex flex-col border-b border-gray-2 last:border-none">
                            <!-- Title -->
                            <a :href="result.url" class="font-semibold text-base hover:underline hover:text-blue-default">
                                {{ result.title }}
                            </a>

                            <!-- Teaser -->
                            <span v-if="result.teaser" class="text-sm text-gray-600 cursor-text">
                                {{ result.teaser }}
                            </span>

                            <!-- Resource Types -->
                            <div class="flex flex-wrap gap-2 mt-1" v-if="result.relatedResourceTypes?.length">
                                <div v-for="resourceType in result.relatedResourceTypes ?? []"
                                     :key="resourceType"
                                     class="flex-initial px-2 py-1 inline-flex items-center justify-start gap-2 rounded-md uppercase font-bold bg-orange-5 text-white cursor-text">
                                    <small class="tag">
                                        {{ resourceType }}
                                    </small>
                                </div>
                                <div v-for="topic in result.relatedTopics ?? []"
                                     :key="topic"
                                     class="flex-initial px-2 py-1 inline-flex items-center justify-start gap-2 rounded-md uppercase font-bold bg-orange-5 text-white cursor-text !mb-0">
                                    <small class="tag">
                                        {{ topic }}
                                    </small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>


                <select v-model="selectedTopic" class="form-field w-full xl:w-1/4">
                    <option value="">Topic</option>
                    <option v-for="topic in searchTopics" :key="topic.id" :value="topic.title">
                        {{ topic.title }}
                    </option>
                </select>

                <select v-model="selectedResourceType" class="form-field w-full xl:w-1/4">
                    <option value="">Resource Type</option>
                    <option v-for="resource in searchResourceTypes" :key="resource.id" :value="resource.title">
                        {{ resource.title }}
                    </option>
                </select>


                <!-- Search Button -->
                <div class="relative">
                    <button @click="performSearch"
                            :data-sitekey="recaptchaKey"
                            data-callback='runSearch'
                            data-action='submit'
                            class="g-recaptcha form-button max-xl:w-full rounded-r-md disabled:opacity-75">
                        <div v-html="MagnifyGlass"
                             class="w-6 h-6 xl:w-[1.25rem] xl:h-[1.25rem]"></div>
                        <span class="xl:sr-only">Search</span>
                    </button>
                    <div class="js-tip absolute -bottom-0.5 left-1/2 transform -translate-x-1/2 ml-4"
                         rel="tooltip"
                         role="tooltip"></div>
                </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { AlgoliaSearchComponent } from '@/js/composables/AlgoliaSearchComponent';
import MagnifyGlass from "/src/svgs/MagnifyGlass.svg?raw";
import { debounce } from "lodash";

const {
    query,
    selectedTopic,
    selectedResourceType,
    searchTopics,
    searchResourceTypes,
    autocompleteResults,
    fetchAutocompleteResults
} = AlgoliaSearchComponent();

const showAutocomplete = ref(false);
const isSearchResultsPage = ref(false);
const recaptchaKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

// ✅ Detect if we are on the search results page
const checkSearchPage = () => {
    isSearchResultsPage.value = window.location.pathname.includes("/search");
};

// ✅ Fetch autocomplete only if not on search results page
const debouncedFetchAutocompleteResults = debounce(async () => {
    if (query.value.length < 3 || isSearchResultsPage.value) {
        showAutocomplete.value = false;
        autocompleteResults.value = [];
        return;
    }

    console.log("🔍 Fetching autocomplete results for:", query.value);
    await fetchAutocompleteResults();

    showAutocomplete.value = autocompleteResults.value.length > 0;
}, 400);

// ✅ Ensure autocomplete does not show on `SearchIndex.vue`
watch(query, () => {
    if (!isSearchResultsPage.value) {
        debouncedFetchAutocompleteResults();
    }
});

// ✅ Detect URL changes (for when navigating back/forth)
watch(() => window.location.pathname, checkSearchPage);

// ✅ Ensure query syncs with URL on page load
onMounted(() => {
    checkSearchPage(); // ✅ Set search page detection on mount
    const params = new URLSearchParams(window.location.search);
    query.value = params.get("q") || "";
});

const handleFocus = () => {
    if (!isSearchResultsPage.value && autocompleteResults.value.length > 0) {
        showAutocomplete.value = true;
    }
};

const hideAutocomplete = () => {
    setTimeout(() => {
        showAutocomplete.value = false;
    }, 200);
};

const performSearch = () => {
    const params = new URLSearchParams({
        q: query.value || "",
        topic: selectedTopic.value || "",
        resourceType: selectedResourceType.value || "",
        page: 1
    }).toString();
    window.location.href = `/search?${params}`;
};
</script>


