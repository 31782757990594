<template>
    <div :class="[classnames?.inner]">
        <!-- Image or Video -->
        <div class="w-full mb-6" :class="classnames?.media" v-if="getSrc(card)">
            <figure class="image image--16x9">
                <div class="image-wrapper">
                    <img
                        v-if="getSrc(card)"
                        :src="getSrc(card)"
                        :srcset="getSrcset(card)"
                        :alt="getAlt(card)"
                        loading="lazy"
                    />
                </div>
            </figure>
        </div>

        <!-- Card Body
            Might be linked if Card is for Search instead of Q/A, Blog, etc
        -->
        <div class="flex flex-col gap-6" :class="classnames.content">
            <template v-if="handle != 'qa'">

                <!-- Blog Cards: Tags and Dates
                    These handles might also need to be adjusted depending on the content type.
                -->
                <div class="text-sm uppercase font-black" v-if="card.categories || card.date">
                    <small class="inline-flex gap-1.5 uppercase text-teal" v-if="card.categories && card.categories.length > 0">
                        <span class="text-sm" v-for="(category, index) in card.categories" :key="index">
                            <template v-if="category">
                                {{ category.title }}
                            </template>
                        </span>
                    </small>
                    <span class="text-gray-3" v-if="card.categories && card.categories.length > 0">&nbsp;•&nbsp;</span>
                    <time class="text-gray-3" v-if="card.date  && !checkSetting(card?.settings?.hideDate)">{{ date(card.date) }}</time>
                </div>

                <!-- Resource Types
                    These handles might also need to be adjusted depending on the content type.
                -->
                <ul class="flex flex-wrap gap-2" v-if="card.resourceType && card.resourceType.length > 0">
                    <li
                        v-for="(type, index) in card.resourceType"
                        :class="'flex-initial px-2.5 py-1 text-xs inline-flex items-center justify-start gap-2 text-gray-3 ' + getLabelClasses(backgroundColor, 'outline')"
                        :key="index"
                    >
                        <!-- @TODO: Make sure we're using the right icons -->
                        <Icon v-if="type.hasOwnProperty('value')" :name="`resource-types/${camelCase(type.value)}`" :class="'w-4 h-4 fill-' + getColor(backgroundColor)" /> <span>{{ type.label ? type.label : type.title }}</span>
                    </li>
                </ul>
            </template>

            <!-- Title -->
            <h2 class="heading-04" v-if="card.title">{{ card.title }}</h2>

            <!-- QA Cards: Question
                These handles might also need to be adjusted depending on the content type.
            -->
            <h2 class="heading-04 flex items-center" v-if="card.question">
                <span class="relative mr-4 ">
                    <span class="inline-flex justify-center items-center min-h-8 min-w-8 h-8 w-8 p-2.5 rounded-full bg-orange-default [&>svg]:w-full [&>svg]:h-full" v-html="Q"></span>
                    <img v-if="card.askerImage" :src="card.askerImage.url" :alt="card.askerImage.alt" class="absolute inset-0 w-full h-full object-cover rounded-full" />
                </span>
                <div v-html="card.question"></div>
            </h2>

            <!-- Internal Teaser -->
            <div class="body" v-if="card.body || card.excerpt" v-html="card.body ? card.body : card.excerpt"></div>

            <!-- QA Cards: Answer -->
            <div class="body flex items-start" v-if="card.answer">
                <span class="relative mr-4">
                    <span class="inline-flex justify-center items-center min-h-8 min-w-8 h-8 w-8 p-2.5 rounded-full bg-orange-default [&>svg]:w-full [&>svg]:h-full" v-html="A"></span>
                    <img v-if="card.answererImage" :src="card.answererImage.url" :alt="card.answererImage.alt" class="absolute inset-0 w-full h-full object-cover rounded-full" />
                </span>
                <div v-html="card.answer"></div>
            </div>

            <!-- These should be ignored by QA cards -->
            <template v-if="handle != 'qa'">
                <!-- ?
                    These handles might also need to be adjusted depending on the content type.
                <CardTags :tags="card.tags" :classes="getLabelClasses(backgroundColor, 'solid')" />
                -->


                <!-- Topics
                <CardTags :tags="card.topics" :classes="getLabelClasses(backgroundColor, 'solid')" />
                -->


                <!-- Essential Support
                    These handles might also need to be adjusted depending on the content type.
                <CardTags :tags="card.support" :classes="getLabelClasses(backgroundColor, 'solid')" />
                -->

                <CardTags :tags="combineTags([card.tags, card.topics, card.support])" :classes="getLabelClasses(backgroundColor, 'solid')" />

            </template>
        </div>
    </div>
</template>

<script setup>
    import CaretRight from '/src/svgs/CaretRight.svg?raw';
    import Q from '/src/svgs/Q.svg?raw';
    import A from '/src/svgs/A.svg?raw';
    import Icon from '../pieces/Icon.vue';
    import CardTags from './CardTags.vue';
    import {camelCase} from 'lodash';

    const props = defineProps({
        handle: {
            type: String,
            required: false
        },
        card: {
            type: Object,
            required: true
        },
        classnames: {
            type: [Array, Object],
            default: () => []
        },
        backgroundColor: {
            type: String,
            require: false,
            default: "var(--orange-2)"
        },
        labelTextColor: {
            type: String,
            require: false,
            default: "text-white"
        }
    });

    /* Prepare the date for display */
    const date = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    /* Function to check if a setting is defined */
    const checkSetting = (setting) => {
        if (setting === undefined) {
            return false;
        }

        return setting;
    };

    /* Combine tags */
    const combineTags = (tags) => {
        let combined = [];

        tags.forEach(tag => {
            if (tag) {
                combined = combined.concat(tag);
            }
        });

        // Remove duplicates
        combined = combined.filter((tag, index) => {
            return combined.indexOf(tag) === index;
        });

        // Sort alphabetically
        // Check if the tag is an object with a label property, if so, sort by label
        // Otherwise, sort by the tag itself (string)
        if (combined && combined[0] && combined[0].label) {
            combined.sort((a, b) => a.label.localeCompare(b.label));
        } else {
            combined.sort();
        }

        return combined;
    };

    function getColor(bgColor) {
        let blue = ['var(--blue-4)', 'var(--blue-6)', 'var(--blue-8)', 'var(--blue-9)'];
        let orange = ['var(--orange-2)', 'var(--orange-7)'];
        let white = ['var(--white)', 'var(--white-1)'];
        let yellow = ['var(--yellow-4)'];
        let purple = ['var(--purple-1)', 'var(--purple-3)'];
        let green = ['var(--green-3)', 'var(--green-4)'];
        let color = '';

        // For each color group, see if this color matches and, if so, assign it the right colors, also based on type
        if (blue.includes(bgColor)) {
            color = 'blue-default';
        } else if (orange.includes(bgColor)) {
            color = 'orange-5';
        } else if (white.includes(bgColor)) {
            color = 'gray-3';
        } else if (yellow.includes(bgColor)) {
            color = 'yellow-default';
        } else if (purple.includes(bgColor)) {
            color = 'purple-3';
        } else if (green.includes(bgColor)) {
            color = 'green-5';
        }

        return color;
    };

    function getLabelClasses(bgColor, type) {
        let color = getColor(bgColor);
        let classes = 'rounded-md uppercase font-bold ';

        classes += type == 'solid' ? ` bg-${color} text-white` : ` border-${color} border-2 fill-${color}`;

        return classes;
    };

    function getSrc(card) {
        if (card.video && card.video.thumbnail?.length > 0) {
            return card.video.thumbnail;
        } else if(card.image && card.image.hasOwnProperty('url') && card.image.url?.length > 0) {
            return card.image.url;
        }

        return null;
    };

    function getSrcset(card) {
        if (card.image && card.image.hasOwnProperty('srcset') && card.image.srcset?.length > 0) {
            return card.image.srcset;
        }

        return null;
    };

    function getAlt(card) {
        if(card.video && card.video.alt?.length > 0) {
            return card.video.alt;
        } else if (card.image && card.image.hasOwnProperty('alt') && card.image.alt?.length > 0) {
            return card.image.alt;
        }

        return '';
    };
</script>
